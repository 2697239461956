.container {
  display: flex;
  margin-bottom: 1rem;
  column-gap: 1rem;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}