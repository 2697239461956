:global {
    ::-webkit-scrollbar {
        height: 1rem !important;
        width: 0.8rem !important;
    }

    ::-webkit-scrollbar-track {
        background: #edeef0 !important;
        border-radius: 0.4rem !important;
    }

    ::-webkit-scrollbar-thumb {
        background: #d4d5d6 !important;
        border-radius: 0.4rem !important;
    }

    .appContainer {
        height: -webkit-fill-available;
    }
}