.formContainer {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
}

.formMobileContainer {
  flex-basis: 90%;
}

.container {
  height: 100vh;
  display: flex;
}

.mobileContainer {
  padding: 2rem;
  justify-content: center;
  align-items: center;
  left: auto;
  height: -webkit-fill-available;
}

.imageContainer {
  flex-basis: 80%;
}