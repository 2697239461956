.container {
    display: flex;
    border-bottom: 1px solid rgb(213, 213, 213);
}

.mobileContainer{
    flex-direction: column;
}

.actionHeader {
    padding: 2rem;
}