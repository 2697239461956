.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.button {
    height: 4rem;
}

.rightContainer{
    display: flex;
    align-items: center;
    column-gap: 1rem;
}