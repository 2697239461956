.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    width: 100%;
    padding: 0.5rem;
}