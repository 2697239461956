.container {
  height: 87vh;
  padding: 2rem;
  overflow-y: scroll;
 
}

.sectionContainer {
 display: flex;
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  column-gap: 5rem;
  
}

.sectionName{
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
}