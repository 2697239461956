.container{
    display: flex;
    align-items: center;
}

.dropDown{
    padding: 1rem;
    width: 3rem;
    border: 1px solid #8b8b8b8b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #161616;
}

.pageSize{
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
    border-right: 1px solid #161616;
    padding-right: 2rem;
}

.pageNum{
    padding-left: 2rem;
    display: flex;
    align-items: center;
    column-gap: 1.2rem;
}

.active{
    color: rgb(68, 131, 131);
    font-weight: bold;
}