.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.upper {
    order: -1;
}

.lower {
    margin-top: auto;
    padding-bottom: 10px;
}