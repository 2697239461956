.container{
    display: flex;
    height: 12rem;
    align-items: center;
    justify-content: space-between;
    border-top: 0.1rem solid #d4d5d6;
    border-bottom: 0.1rem solid #d4d5d6;
    cursor: grab;
    user-select: none;
    z-index: 1000;
 
    .item{
     height: 100%;
     display:flex;
     align-items: center;
     column-gap: 1.6rem;
     padding-left: 1rem;
    }
 
    .actionItem{
     width: 10rem;
     border-left:0.1rem solid #d4d5d6 ;
     height: 100%;
     display:flex;
     align-items: center;
     padding-left: 1rem;
    }
    
    .image{
        width: 10rem;
        height: 10rem;
    }
 }