.contentContainer {
    max-height: 15rem;
    overflow: auto;

    .contentItem {
        border-bottom: 0.1rem solid #D4D5D6;
        padding: 0.5rem 0;
    }

    &:last-child{
        .contentItem{
            border-bottom: none;
        }
    }
}

