.previewContainer {
    display: flex;
    align-items: center;
    position: relative;
}

.previewContent {
    position: relative;
}

.iconContainer {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 10px;
    color: #fff !important;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.5);
    }

    opacity: 1 !important;
}

.previewContent:hover .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    width: 100%;
    height: 100%;
}

.otherpreviewContainer {
    display: flex;
    align-items: center;
}

.videoContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.6rem;
}