.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // overflow: auto;
}

.header {
    height: 5.2rem;
    position: sticky;
}

.body {
    max-height: calc(100% - 5.2rem);
    overflow: auto;
}