

.container {
  display: flex;
  align-items: center;
  width: 300px;
  margin-bottom: 3rem;
}

.button {
  display: flex;
  align-items: center;
  row-gap: 10px;
}

.buttonLabel {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}
