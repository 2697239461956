.drawerContainer {
    :global(.ant-drawer-header) {
        padding: 1rem !important;
        background-color: #D3D4D5;


        :global(.ant-drawer-header-title) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
    }

    :global(.ant-drawer-body) {
        padding: 0rem;
    }

    :global(.ant-drawer-footer) {
        padding: 1rem !important;
        border-top: 0.1rem solid #D3D4D5;
    }


}

.saveComponent {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.error {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    color: #d11010;
}