.container {
    height: 60vh;
    overflow: hidden;
}

.imageUpload {
    height: 10rem !important;
    width: 12rem !important;
}

.mobileTableClassName{
    height: -webkit-fill-available;
    overflow: scroll !important;
}