.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.navbarContainer {
  display: flex;
  align-items: center;
  flex-basis: 3rem;
  background-color: #404757;
  min-height: 6rem;
}

.imageContainer {
  width: 10rem;
  height: 3.5rem;
}

.searchInput {
  width: 100%;
  margin: 0 0.8rem;
}