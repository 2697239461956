.headerComponent{
    display: flex;
    align-items: center;
    background-color: #EDEEF0;
    font-weight: 500;
}

.headerContainer{
    font-size: 1.6rem;
    border-right: 1px solid #d5d4d6;
    padding: 2rem;
}

.actionContainer{
    font-size: 1.6rem;
    padding: 2rem;
}