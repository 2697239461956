.container {
    display: flex;
    flex-direction: column;
    width: 100%
}

.upload {
    height: 20rem;
    width: 20rem;
}

.previewClassName{
    height: 20rem;
    width: 20rem;
    object-fit: contain;
    border: 1px solid #d4d5d6;
    display: flex;
    align-items: center;
    background-color: #fafafa;
    justify-content: center;
}