@use "../styles" as component;

.primary {
  background-color: component.$denim;
  opacity: 0.95;
  color: component.$white;
  border-radius: component.$btn-border-radius;

  &:hover {
    background-color: component.$denim !important;
    opacity: 1;
    color: component.$white !important;
    border-radius: component.$btn-border-radius;
  }

  &:disabled {
    background-color: component.$denim !important;
    opacity: 1;
    color: component.$white !important;
    border-radius: component.$btn-border-radius;
  }
}

.secondary {
  @include component.border;
  font-weight: component.$font-weight-medium;
  border-radius: component.$btn-border-radius;

  &:hover {
    color: component.$charcoal !important;
    @include component.border($border-color: component.$denim);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--active {
    font-weight: bold;
    background-color: component.$denim;
    color: #fff;
  }
}

.tertiary {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-size: 1.6rem;

  &:hover {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
  }

  &:disabled {
    color: black;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--active {
    font-weight: bold;
    background-color: component.$denim;
    color: #fff;
  }
}

.link {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  color: blue;

  &:hover {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
    color: blue;
  }
}

.inverseText {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  font-size: 1.6rem;
  color: #fff !important;

  &:hover {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
    color: #fff !important;
  }

  &:disabled {
    color: ffff;
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--active {
    font-weight: bold;
    background-color: component.$denim;
    color: #fff;
  }
}