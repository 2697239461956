.tabsContainer {
    display: flex;
    cursor: pointer;
    margin-bottom: 1rem;
    border-top: 0.1rem solid #d4d5d6;
    border-bottom: 0.1rem solid #d4d5d6;
    column-gap: 0.2rem;
    overflow-y: auto;

    .tab {
        white-space: nowrap;
        display: flex;
        column-gap: 0.6rem;
        padding: 1.5rem 2rem 1.5rem 1rem;
        font-size: 1.6rem;
        font-weight: 550;
        border-left: 0.1rem solid #d4d5d6;
        transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out, opacity 0.5s ease-in-out;

        &__active {
            border-left: none;
            border-bottom: 0.1rem solid #0060ff;
            background-color: #ebf4ff;

            +.tab {
                border-left: none;
            }
        }

        &:first-child {
            padding-left: 1rem;
            border-left: none;
        }
    }
}

.tabComponent {
    padding: 1rem;
    height: 78vh;
    overflow: auto;
}

.mobileComponent {
    height: 100vh !important;
    overflow: scroll !important;
}

.renderer {
    height: 100%;
}