.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    width: 100%;
    padding: 1rem;
}

.forgotPassword {
    border: none !important;
    outline: none !important;
    color: rgb(4, 33, 137);
}

.forgotPasswordContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
}