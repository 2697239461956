.microZonationValuesContainer{
    overflow: auto;
    height: 6.5rem;

    .level{
        padding: 0.5rem 0;
        border-bottom: 0.1rem solid #d4d5d6;

        &:last-child{
            border: none;
        }
    }

}

