$charcoal: #282828;
$black: #000000;
$mordantRed: #f52908;
$denim: #4285F4;
$white: #fff;
$platinum: #d4d5d6;
$ashGray: #969aa3;
$lightCyan: #d4ebf2;
$babyBlue: #cfe5ff;
$purpleBlue: #9762dc;
$redOrange: #ff4d4d;
$yellowGreen: #ffd94a;
$cyanBlue: #4abef3;
$bluePurple: #6e54c8;
$lightLavender: #8a2be2;
$lightPink: #ff6347;
$lightGreen: #9acd32;
$green: #008000;
$lightTurquoise: #00bfff;
$lightMagenta: #6a5acd;
$lightCoral: #ff7f50;
$lightSkyBlue: #87cefa;
$lightRose: #ff9daa;
$lightYellow: #ffffe0;
$lightApricot: #ffb6c1;
$lightSeafoamGreen: #20b2aa;
$lightSalmon: #ffa07a;
$lightBeige: #f5f5dc;
$lightPeach: #ffdab9;
$lightLime: #d3ffce;
$lightPeriwinkle: #cc66cc;
$lightMint: #bdfcc9;
$lightLavenderPink: #fbaed2;
$lightCyan: #e0ffff;
$lightMauve: #f984ef;
$lightPastelGreen: #77dd77;
$lightPinkish: #ffd1dc;
$lightCream: #fffdd0;
$lightLightGreen: #90ee90;

