.dashboardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.sidebar {
  background-color: #404757;
  min-width: 6rem;
}

.contentContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.header {
  background-color: #404757;
  min-height: 6rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.content {
  width: calc(100vw - 6rem);
  height: calc(100vh - 7rem);
  overflow: auto;
}

.mobileContent {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  overflow: hidden;
}

.content::-webkit-scrollbar {
  height: 1rem;
  width: 0.6rem;
  background: #d4d5d6;
}

.content::-webkit-scrollbar-thumb:horizontal {
  background: #969aa3;
  border-radius: 0.2rem;
  cursor: pointer;
}

.left {
  order: -1;
}

.leftIcon {
  padding-left: 1rem;
}

.iconContainer {
  order: -1 !important;
}

.right {
  order: 1;
  padding-right: 3rem;
}