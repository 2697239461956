.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: row;
}

.sidebarContainer {
    min-width: 6rem;
    display: flex;
    justify-content: center;
    background-color: #404757;
    padding-top: 2rem;
}

.contentContainer {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.mobileContainer {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.mobileContentContainer {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}