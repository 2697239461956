.container {
    font-size: 1.8rem;
    // border-right: 1px solid black;
    padding: 2rem;
}

.header {
    width: var(--header-width, 20rem) !important;
    flex-shrink: 0;
    overflow: hidden !important;
    background-color: #EDEEF0;
    font-weight: 500;
}