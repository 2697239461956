.container{
    max-height: 150px;
    overflow: auto;
}

.listItem{
display: flex;
width: 100%;
align-items: center;
justify-content: space-around;
}

  