@use "../styles" as component;

.input {
  @include component.border;
  border-radius: component.$input-border-radius;
  padding: component.$component-padding;
  font-size: component.$font-normal;
  min-height: component.$input-min-height;
  width: 100%;
  &:hover {
    outline: none;
    @include component.border;
  }
  &:focus {
    outline: none;
    @include component.border($border-color: component.$denim);
  }
  &:disabled{
    border: 1px solid rgb(133,133,133);
    color:rgb(133,133,133)
  }
}

.inputContainer {
  @include component.flex($flex-direction: column);
  row-gap: 0.8rem;
  width: 100%;
}

.invalid {
  border-color: component.$mordantRed;
}
