@use "../styles" as component;

.textAreaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.8rem;
}

.textArea {
  width: 100%;
}

.textArea.disabled {
  color: black !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}
