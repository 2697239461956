.container {
    border-right: 0.1rem solid #d4d5d6;
    padding: 2rem;
    width: var(--header-width, 20rem) !important;
    flex-shrink: 0;
    // overflow: hidden !important;
}

.mobileContainer{
    width: 100%;
    border: none;
}