.container {
   display: flex;
   background-color: #EDEEF0;
   height: 5rem;
   align-items: center;
   justify-content: space-between;

   .item {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 1rem;
   }

   .actionItem {
      width: 10rem;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 1rem;
   }

}