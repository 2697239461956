.avatarA {
    background: linear-gradient(130deg, #007bff, #800080);
}

.avatarB {
    background: linear-gradient(130deg, #007bff, #ff8c00);
}

.avatarC {
    background: linear-gradient(130deg, #008000, #90ee90);
}

.avatarD {
    background: linear-gradient(130deg, #007bff, #00ffff);
}

.avatarE {
    background: linear-gradient(130deg, #800080, #007bff);
}

.avatarF {
    background: linear-gradient(130deg, #00ffff, #9370db);
}

.avatarG {
    background: linear-gradient(130deg, #ff8c00, #ffb6c1);
}

.avatarH {
    background: linear-gradient(130deg, #008000, #90ee90);
}

.avatarI {
    background: linear-gradient(130deg, #00ffff, #afe4de);
}

.avatarJ {
    background: linear-gradient(130deg, #9370db, #ff77ff);
}

.avatarK {
    background: linear-gradient(130deg, #007bff, #800080);
}

.avatarL {
    background: linear-gradient(130deg, #007bff, #ff8c00);
}

.avatarM {
    background: linear-gradient(130deg, #008000, #90ee90);
}

.avatarN {
    background: linear-gradient(130deg, #007bff, #00ffff);
}

.avatarO {
    background: linear-gradient(130deg, #800080, #007bff);
}

.avatarP {
    background: linear-gradient(130deg, #00ffff, #9370db);
}

.avatarQ {
    background: linear-gradient(130deg, #ff8c00, #ffb6c1);
}

.avatarR {
    background: linear-gradient(130deg, #008000, #90ee90);
}

.avatarS {
    background: linear-gradient(130deg, #00ffff, #afe4de);
}

.avatarT {
    background: linear-gradient(130deg, #9370db, #ff77ff);
}

.avatarU {
    background: linear-gradient(130deg, #007bff, #800080);
}

.avatarV {
    background: linear-gradient(130deg, #007bff, #ff8c00);
}

.avatarW {
    background: linear-gradient(130deg, #008000, #90ee90);
}

.avatarX {
    background: linear-gradient(130deg, #007bff, #00ffff);
}

.avatarY {
    background: linear-gradient(130deg, #800080, #007bff);
}

.avatarZ {
    background: linear-gradient(130deg, #9370db, #00ffff);
}

.avatarBlack {
    background: #5D6470;
    color: white;
    border-radius: 0 !important;
    border-bottom: 5px solid azure;
}

.avatarGray{
    background: #D4D5D6;
    color: #161616;
    border-radius: 0 !important;
}