.defaultSectionContainer {
    width: 75%;
}

.defaultSectionContainerRow {
    column-gap: 5rem !important;
}

.audio {
    margin-bottom: 2rem;
}