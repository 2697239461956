.container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
}

.imageUpload {
  width: 100%;
  height: 8rem;
}
