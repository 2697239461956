@mixin hide-scrollbar() {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

@mixin flex(
  $flex-direction: row,
  $flex-wrap: nowrap,
  $justify-content: flex-start,
  $align-items: flex-start
) {
  display: flex;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  justify-content: $justify-content;
  align-items: $align-items;
}
