@use "../variables/font" as font;
@use "../variables/colors" as color;

@mixin typography(
  $font-size: font.$font-medium,
  $color: color.$black,
  $font-weight: font.$font-weight-normal
) {
  font-size: $font-size;
  color: $color;
  font-weight: $font-weight;
}
