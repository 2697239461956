@use "../styles" as component;

.selectContainer {
  @include component.flex($flex-direction: column);
  row-gap: 1rem;
}

.select {
  @include component.border;
  border-radius: component.$input-border-radius;
  padding: component.$component-padding;
  font-size: component.$font-normal;
  width: 100% !important;

  &:focus {
    @include component.border($border-color: component.$denim);
    border-radius: component.$input-border-radius !important;
  }

  * {
    @include component.hide-scrollbar;
  }
}

.container {
  padding: 0;
  height: fit-content;
}

.control {
  border-width: 0;
  border-radius: 0.2rem;
  min-height: fit-content;
  height: fit-content;
}

.indicatorContainer {
  min-height: 1rem;
}

.input {
  // min-height: 2.8rem;
}

.menuList {
  padding: 0 !important;
  z-index: 100000 !important;
}

.option {
  background: transparent !important;
  color: component.$black !important;
}

.dropdownIndicator {
  @include component.transition;
}

.dropdownIndicatorOpen {
  @include component.transition;
  transform: rotate(180deg);
}

.menuContainer{
  z-index: 100000;
}