/* Font sizes */
$font-xsmall: 0.9rem;
$font-xxsmall: 0.64rem;
$font-msmall: 1.1rem;
$font-small: 1.2rem;
$font-normal: 1.4rem;
$font-xnormal: 1.5rem;
$font-medium: 1.6rem;
$font-xmedium: 1.7rem;
$font-large: 1.8rem;
$font-mlarge: 2rem;
$font-xlarge: 2.1rem;
$font-xxlarge: 2.4rem;
$font-xxmlarge: 3.2rem;
$font-xxxlarge: 4rem;

/* Font-weight */
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

$line-height-normal: 1.6rem;