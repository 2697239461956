@use "../styles" as component;

:global(.ant-checkbox) {
  border-radius: component.$input-border-radius;
  border: none !important;
}

:global(.ant-checkbox-inner) {
  @include component.border($border-color: component.$platinum);
  border-radius: component.$input-border-radius;
  outline: none;
  &:hover {
    @include component.border($border-color: component.$denim);
  }
}
