@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

html {
  font-size: 62.5%;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  font-size: 1.6rem;
  line-height: 1.5rem;
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --vh: calc(100vh - env(safe-area-inset-bottom));
}