.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 7rem);
    width: calc(100vw - 8.4rem);
    display: hidden;
}

.mobileContainer {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
}

.header {
    color: #161616;
    font-weight: 600;
    font-size: 2.2rem;
    padding: 0.8rem 1.2rem;
    border-bottom: 1px solid #d4d5d6;
    flex-basis: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mobileheader {
    width: -webkit-fill-available;
    font-weight: 550;
    font-size: 1.7rem;
}

.content {
    padding: 1rem 1.2rem;
    overflow: hidden;
    width: 100%;
}

.mobileContent {
    width: -webkit-fill-available;
}

.sectionHalfWidth {
    width: 50%;
}

.banner {
    padding: 0 1.2rem;
    width: 100%;
}