.subHeader {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // flex-basis: 3rem;
}

.mobileSubHeader {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.searchBar {
    width: 30rem;
}

.mobileSearchBar {
    width: 100%;
}

.actionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.2rem;
    cursor: pointer;
}

.actionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.subHeader {
    margin-bottom: 0.5rem;
}

.table {
    flex: 1;
    // flex-grow: 1;
}

.tableWhole {
    flex: 1;
    // flex-grow: 1;
}

.pagination {
    border-top: 1px solid #8b8b8b;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    flex-basis: 2rem;
    background-color: white;
    z-index: 2;
}

.tableBody {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.tableHeader {
    font-size: 1.4rem;
    font-weight: 650;
    color: #161616;
}

:global(.ant-table) {

    scrollbar-color: initial !important;

    ::-webkit-scrollbar {
        height: 1rem !important;
        width: 0.8rem !important;
    }

    ::-webkit-scrollbar-track {
        background: #edeef0 !important;
        border-radius: 0.4rem !important;
    }

    ::-webkit-scrollbar-thumb {
        background: #d4d5d6 !important;
        border-radius: 0.4rem !important;
    }
}

:global(.ant-table-cell) {

    ::-webkit-scrollbar {
        height: 0.8rem !important;
        width: 0.8rem !important;
    }

    ::-webkit-scrollbar-track {
        background: #edeef0 !important;
        border-radius: 0.4rem !important;
    }

    ::-webkit-scrollbar-thumb {
        background: #d4d5d6 !important;
        border-radius: 0.4rem !important;
    }
}