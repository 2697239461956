.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
}

.mobileMultiContainer {
    display: flex !important;
    flex-direction: column !important;
    border-left: 1px solid #838383;
}

.multiContainer {
    display: grid;
    grid-template-columns: repeat(3, 30rem);
    grid-template-rows: repeat(3);
}

.multiContainer>* {
    border-bottom: 1px solid #838383;
    border-right: 1px solid #838383;
    padding: 1rem;
    max-height: 20rem;
    overflow: auto;
}

.multiContainer> :nth-child(3n + 1) {
    border-left: 1px solid #838383;
}

.multiContainer> :nth-child(-n+3) {
    border-top: 1px solid #838383;
}

.multiContainer> :nth-child(3n) {
    border-right: 1px solid #838383;
}

.multiContainer> :nth-child(1) {
    border-left: 1px solid #838383;
}


.title {
    margin-bottom: 1rem;
}