@use '../../../styles' as component;

.option {
  display: flex !important;
  align-items: center !important;
  column-gap: 10px;
  cursor: pointer;
  &:active {
    background: transparent !important;
  }
  &:hover {
    background-color: component.$babyBlue !important;
  }
}
