@use "colors" as colors;

$border-width-default: 0.1rem;
$border-color-default: colors.$ashGray;

// input border
$input-border-radius: 0.2rem;
$btn-border-radius: 0.2rem;

@mixin border(
  $border-width: $border-width-default,
  $border-color: $border-color-default
) {
  border: $border-width solid $border-color;
}
