.container {
   width: 60rem;
   height: 40rem;
}

.imageContainer {
   width: 100% !important;
   display: flex;
   flex-direction: row;
   column-gap: 2.4rem;
   row-gap: 2.4rem;
   flex-wrap: wrap;
   overflow-y: auto;
}

.mobileContainer {
   width: 40rem;
   height: 30rem;
   overflow: scroll !important;
}