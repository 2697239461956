audio::-webkit-media-controls-panel {
    background-color: white;
    padding: 0 !important;
    margin: 0 !important;
}

.title {
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
}